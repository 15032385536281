gsap.registerPlugin(ScrollTrigger);

const initCards = () => {
  const cardsWrappers = document.querySelector(".cards");

  if (cardsWrappers) {
    const cards = cardsWrappers.querySelectorAll(".cards__item");

    if (cards && cards.length) {
      cards.forEach((card, index) => {

        const image = card.querySelector(".cards__image img");

        gsap.to(image, {
          duration: 1.0,
          scale: 1.05, // Adjust this value to control the amount of zoom
          ease: "none",
          scrollTrigger: {
            trigger: image,
            start: "top center", // Change this if you want a different start point
            end: "bottom center", // Change this if you want a different end point
            scrub: 0.5
          },
        });

        gsap.to(card, {
          duration: 1.0,
          top: 0,
          ease: "none",
          scrollTrigger: {
            trigger: card,
            start: () => "top top",
            end: () => "+=200",
            scrub: 0.5,
          },
        });
      });
    }
  }
};

document.addEventListener("DOMContentLoaded", function () {
  initCards();
});
